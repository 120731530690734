import React from 'react';
import { Typography, Box, Stack, Card, CardContent } from '@mui/material';
import { Button } from 'gatsby-material-ui-components';

import { ChevronRight, Star } from '@mui/icons-material';

import Layout from '@gaia/Layout';
import DownloadButtons from '@gaia/components/Buttons/DownloadButtons';
import ActionStack from '../components/Home/ActionStack';
import AppScreensMain from '../components/Home/AppScreensMain';
import AppScreensAct from '../components/Home/AppScreensAct';
import ReviewQuotes from '../components/Home/ReviewQuotes';

import SEO from '../components/seo';
import emmisionsImage from '../images/emissions.svg';
import checkCircleImage from '../images/check-circle.svg';
import communityImage from '../images/community.svg';
import highFive from '../images/high-five.svg';
import worldDualities from '../images/world-dualities.svg';

const Home = () => (
    <Layout>
        <SEO title="Home" />
        <Stack
            component="section"
            sx={{ mb: { xs: 9, sm: 6 }, flexDirection: { sm: 'row' }, gap: { sm: 7 } }}
        >
            <Box flexBasis="55%">
                <Typography variant="h1" sx={{ mb: { xs: 2, sm: 4 } }}>
                    Take action for{' '}
                    <Box sx={{ color: 'primary.main' }} component="span">
                        people & planet
                    </Box>{' '}
                    with Earth Hero
                </Typography>
                <Typography paragraph variant="subtitle1" sx={{ mb: 4 }}>
                    Discover hundreds of actions to care for our planet. Take action to lead a
                    better, more sustainable life with the free climate change app. It's our future
                    to choose.
                </Typography>
                <DownloadButtons sx={{ mb: 4, justifyContent: { xs: 'center', sm: 'start' } }} />
                <Typography
                    variant="body1"
                    paragraph
                    sx={{ mb: 4, textAlign: { xs: 'center', sm: 'left' } }}
                >
                    Join a global community from 150+ countries.
                </Typography>
            </Box>
            <Box flexBasis="45%">
                <AppScreensMain />
            </Box>
        </Stack>
        <Stack component="section" alignItems="center" sx={{ mb: { xs: 9, sm: 11 } }}>
            <Typography variant="h2" sx={{ mb: 2, textAlign: { xs: 'center', sm: 'left' } }}>
                How does Earth Hero work?
            </Typography>
            <Typography
                variant="body1"
                paragraph
                sx={{ mb: { xs: 4, sm: 8 }, width: { sm: '60%' } }}
                textAlign="center"
            >
                Earth Hero provides scientifically grounded tools and ideas to empower all who want
                to reduce emissions, improve lives, and care for our shared planet.
            </Typography>
            <Stack sx={{ flexDirection: { sm: 'row' }, gap: { xs: 5, sm: 0 } }}>
                <ActionStack
                    title="1. Track Your Progress"
                    summary="Measure your impact, track your growth, and see how you compare."
                    image={emmisionsImage}
                />
                <ActionStack
                    title="2. Achieve Climate Actions"
                    summary="Discover fun actions to improve your life and reduce climate pollution."
                    image={checkCircleImage}
                />
                <ActionStack
                    title="3. Join a Community"
                    summary="Mobilize with change-makers actively creating a better world."
                    image={communityImage}
                />
            </Stack>
        </Stack>
        <Stack
            component="section"
            alignItems="center"
            gap={16}
            sx={{ mb: { xs: 9, sm: 11 }, flexDirection: { sm: 'row' }, gap: { sm: 7 } }}
        >
            <Box flexBasis="55%">
                <Typography variant="h2" sx={{ mb: 2 }}>
                    Inspiring a billion climate activists
                </Typography>
                <Typography variant="body1" paragraph sx={{ mb: 3 }}>
                    Set climate pollution reduction targets based on science-based IPCC
                    recommendations. Get personalized suggestions. Connect with others to drive
                    system change so your climate actions have a multiplier effect.
                </Typography>
                <DownloadButtons sx={{ mb: 4, display: { xs: 'none', sm: 'flex' } }} />
            </Box>
            <Box flexBasis="45%" textAlign="center">
                <img
                    src={worldDualities}
                    alt="A split world globe. Half green, half polluted."
                    loading="lazy"
                    style={{ maxWidth: '100%' }}
                />
            </Box>
        </Stack>
        <Stack
            component="section"
            alignItems="center"
            sx={{
                mb: { xs: 9, sm: 11 },
                flexDirection: { sm: 'row-reverse' },
                gap: { sm: 16 },
            }}
        >
            <Box flexBasis="55%">
                <Typography variant="h2" sx={{ mb: 2 }}>
                    Making it easy to act on climate change
                </Typography>
                <Typography variant="body1" paragraph sx={{ mb: { xs: 5, sm: 3 } }}>
                    From fun, social activities to ambitious carbon footprint reductions, take
                    actions that are right for you and see the impact they have.
                </Typography>
                <DownloadButtons sx={{ mb: 5, justifyContent: { xs: 'center', sm: 'start' } }} />
            </Box>
            <Box flexBasis="45%">
                <AppScreensAct />
            </Box>
        </Stack>
        <Stack
            component="section"
            alignItems="center"
            justifyContent="center"
            sx={{ mb: { sm: 11 }, flexDirection: { sm: 'row' }, gap: { xs: 5, sm: 25 } }}
        >
            <Box flexBasis="55%">
                <Typography variant="h2" sx={{ mb: 2 }}>
                    Heroics start with a small step
                </Typography>
                <Typography variant="body1" paragraph sx={{ mb: { xs: 2, sm: 3 } }}>
                    We are a global group of volunteers who came together to address the climate
                    emergency. If you can make time to make a difference, let’s talk.
                </Typography>
                <Button
                    to="/get-involved"
                    variant="outlined"
                    color="secondary"
                    endIcon={<ChevronRight />}
                    sx={{ size: { xs: 'medium', sm: 'large' } }}
                >
                    Get Involved
                </Button>
            </Box>
            <Box flexBasis="45%" textAlign="center">
                <img width="70%" src={highFive} alt="High five" loading="lazy" />
            </Box>
        </Stack>
        <Box sx={{ mb: { sm: 13 } }}>
            <ReviewQuotes />
        </Box>
        <Box justifyContent="center" sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <Card variant="outlined" sx={{ width: '60%' }}>
                <CardContent sx={{ textAlign: 'center' }}>
                    <Box sx={{ paddingY: 3 }}>
                        <Typography variant="h3" sx={{ mb: 2 }}>
                            Free App Available Now
                        </Typography>
                        <Typography paragraph sx={{ mb: 4 }}>
                            Join our global community of users from 145+ countries on our free app
                            today!
                        </Typography>
                        <DownloadButtons direction="horizontal" sx={{ justifyContent: 'center' }} />
                    </Box>
                </CardContent>
            </Card>
        </Box>
    </Layout>
);

export default Home;
