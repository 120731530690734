import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Stack, Box } from '@mui/material';

const AppScreens = () => (
    <Stack flexDirection="row" alignItems="center" justifyContent="center">
        <Box width="45%" marginRight="-12%">
            <StaticImage src="../../images/mobile-app/home.png" alt="mobile app home screen" />
        </Box>
        <Box width="55%">
            <StaticImage
                src="../../images/mobile-app/actions.png"
                alt="mobile app actions screen"
            />
        </Box>
    </Stack>
);

export default AppScreens;
