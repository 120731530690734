import React from 'react';
import { Typography, Box, Stack } from '@mui/material';
import { Star } from '@mui/icons-material';
import CircleIcon from '@mui/icons-material/Circle';
import greatBritainFlag from '../../images/flags/great-britain.svg';
import usaFlag from '../../images/flags/usa.svg';
import indianFlag from '../../images/flags/indian.svg';
import franceFlag from '../../images/flags/france.svg';
import germanyFlag from '../../images/flags/germany.svg';
import brazilFlag from '../../images/flags/brazil.svg';
import canadaFlag from '../../images/flags/canada.svg';
import colombiaFlag from '../../images/flags/colombia.svg';
import italyFlag from '../../images/flags/italy.svg';
import mexicoFlag from '../../images/flags/mexico.svg';
import netherlandsFlag from '../../images/flags/netherlands.svg';

const quotes: { text: string; flag: { label: string; img: any } }[] = [
    {
        flag: {
            label: 'USA flag',
            img: usaFlag,
        },
        text:
            'Love it! Whether you’re just starting your sustainable living journey, have come some way or already nailed it — this app is for you.',
    },
    {
        flag: {
            label: 'Indian flag',
            img: indianFlag,
        },
        text:
            'Thinking about climate change is overwhelming for me, it used to leave me paralyzed. Earth Hero helped me channel climate anxiety into action, focused on everything I have control of.',
    },
    {
        flag: {
            label: 'Great Britain flag',
            img: greatBritainFlag,
        },
        text:
            'Finally - an app with easy, informative tips to save resources, the planet AND money! By far the best of its kind I’ve used.',
    },
    {
        flag: { label: 'France flag', img: franceFlag },
        text:
            'Earth Hero is an incredible app with a wealth of information about climate change and the different actions we can take.',
    },
    {
        flag: { label: 'Germany flag', img: germanyFlag },
        text:
            'This is such a fantastic app. It’s really useful to help manage climate crisis overwhelm, whilst making sure to take action for change!',
    },
    {
        flag: { label: 'Canada flag', img: canadaFlag },
        text:
            'Wherever you are on your climate journey—disinterest, dread, nihilism, rage, optimism, obsession—this app is going to be a great tool for you.',
    },
    {
        flag: { label: 'Netherlands flag', img: netherlandsFlag },
        text:
            'It’s fun to browse and tap and make lists and such, but behind every compelling button or image is a hard-hitting list of tips, with links to credible resources and organizations doing the really hard work, like advancing policy, or innovating climate tech.',
    },
    {
        flag: { label: 'Italy flag', img: italyFlag },
        text:
            'This app makes it so easy to take steps, big and small, to combat our climate emergency.',
    },
    {
        flag: { label: 'Brazil flag', img: brazilFlag },
        text:
            'This app not only provides new ideas for sustainability and climate actions, but it’s also great positive reinforcement. Every time I achieve a goal or update my emissions profile I get a burst of energy that pushes me forward.',
    },
    {
        flag: { label: 'Mexico flag', img: mexicoFlag },
        text:
            'I’ve always spent a lot of time wondering what my own impact is and how I can make it better, and this app has seriously made me so much more determined to take action. Great tool!',
    },
    {
        flag: { label: 'Colombia flag', img: colombiaFlag },
        text:
            'I have been looking for ways to understand the most impactful things I can do to help mitigate my climate impact. This app offers customized analysis and many ideas of actions to take.',
    },
    {
        flag: { label: 'USA flag', img: usaFlag },
        text:
            'This app amazing for people who care about the climate crisis and it inspires you to do more good in the world. The goals you can set for yourself keep you motivated as you achieve them and it is overall a great empowering app.',
    },
];

const ReviewQuotes = () => {
    const [activeStep, setActiveStep] = React.useState(0);
    const quotesPerStep = 3;
    const maxSteps = Math.ceil(quotes.length / quotesPerStep);
    const steps = Array.from({ length: maxSteps }, (_, k) => k);

    const suffleQuotes = React.useMemo(() => {
        const suffle = [...quotes];
        let currentIndex = quotes.length;
        while (currentIndex != 0) {
            let randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [suffle[currentIndex], suffle[randomIndex]] = [
                suffle[randomIndex],
                suffle[currentIndex],
            ];
        }
        return suffle;
    }, []);
    const visibleQuotes = React.useMemo(() => {
        return suffleQuotes.slice(
            activeStep * quotesPerStep,
            activeStep * quotesPerStep + quotesPerStep
        );
    }, [suffleQuotes, activeStep, quotesPerStep]);

    return (
        <Stack>
            <Typography variant="h3" textAlign="center" sx={{ mb: 2 }}>
                People Love Earth Hero!
            </Typography>
            <Stack sx={{ flexDirection: { sm: 'row' }, gap: { xs: 3, sm: 3 } }}>
                {visibleQuotes.map(quote => (
                    <Box textAlign="center" flexBasis="33%">
                        <Stack justifyContent="center" direction="row" sx={{ mb: 2 }} gap={2}>
                            <Star sx={{ color: 'orange.light' }} />
                            <Star sx={{ color: 'orange.light' }} />
                            <Star sx={{ color: 'orange.light' }} />
                            <Star sx={{ color: 'orange.light' }} />
                            <Star sx={{ color: 'orange.light' }} />
                        </Stack>
                        <Typography variant="h3" sx={{ mb: 2 }}>
                            <span role="img" aria-label={quote.flag.label}>
                                <img
                                    width="32"
                                    src={quote.flag.img}
                                    alt={quote.flag.label}
                                    loading="lazy"
                                />
                            </span>
                        </Typography>
                        <Typography variant="body1">{quote.text}</Typography>
                    </Box>
                ))}
            </Stack>
            <Stack justifyContent="center" direction="row" sx={{ mt: 4 }} gap={1}>
                {steps.map(stepNumber => {
                    return (
                        <CircleIcon
                            fontSize="small"
                            onClick={() => setActiveStep(stepNumber)}
                            sx={{
                                ml: 1,
                                mr: 1,
                                p: stepNumber === activeStep ? 0.4 : 0.7,
                                color: theme => theme.palette.grey.light,
                            }}
                        />
                    );
                })}
            </Stack>
        </Stack>
    );
};

export default ReviewQuotes;
