import React from 'react';
import { Typography, Box } from '@mui/material';

interface ActionStackProps {
    title: string;
    summary: string;
    image: string;
}

const ActionStack = ({ title, summary, image }: ActionStackProps) => (
    <Box textAlign="center">
        <Box
            component="img"
            src={image}
            alt=""
            sx={{ display: 'inline-block', marginBottom: { xs: 2, sm: 4 } }}
            loading="lazy"
        />
        <Typography variant="h4" sx={{ mb: 1 }}>
            {title}
        </Typography>
        <Typography variant="body1">{summary}</Typography>
    </Box>
);

export default ActionStack;
