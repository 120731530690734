import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Stack, Box } from '@mui/material';

const AppScreensAct = () => (
    <Stack flexDirection="row" alignItems="center" justifyContent="center">
        <Box width="45%" marginRight="-12%">
            <StaticImage
                src="../../images/mobile-app/emissions.png"
                alt="mobile app emissions screen"
            />
        </Box>
        <Box width="55%">
            <StaticImage src="../../images/mobile-app/action.png" alt="mobile app act screen" />
        </Box>
    </Stack>
);

export default AppScreensAct;
